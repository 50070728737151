import { ButtonBase, styled, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const SRoot = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const Text = styled(Typography)`
  margin-bottom: 30px;
  max-width: 28ch;
  text-align: center;
  font-weight: bold;
`;

const SButton = styled(ButtonBase)`
  background: #7325d6;
  height: 113px;
  width: 290px;
  border-radius: 19px;
  color: #fff;
  font-size: 20px;
`;

const HomePresButton = () => {
  const navigate = useNavigate();
  return (
    <SRoot>
      <Text variant="h3" sx={{ marginBottom: "40px" }}>
        Pour en savoir plus sur SnoezEnfance, c'est par ici :
      </Text>
      <SButton onClick={() => navigate("/concepts")}>Concept</SButton>
    </SRoot>
  );
};

export default HomePresButton;
