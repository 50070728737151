import { styled, Typography } from "@mui/material";
import React from "react";
import Banner from "../common/Banner";

import { motion } from "framer-motion";

const Brand = styled("span")`
  font-weight: bold;
  font-family: Grand Hotel, cursive;
`;

const HomeHeader: React.FC = () => {
  return (
    <Banner cover image="/static/header.jpg">
      <motion.div
        animate={{ scale: [0, 1], opacity: [0, 1] }}
        transition={{ duration: 0.5 }}
      >
        {/* <Box textAlign="center">
          <img style={{ height: "180px" }} alt="logo" src="/static/logo.png" />
        </Box> */}
        <Typography
          textAlign="center"
          maxWidth="23ch"
          fontWeight="normal"
          variant="h1"
          lineHeight="50px"
        >
          <Typography
            variant="h1"
            component="span"
            sx={{ fontSize: "52px", display: "block", lineHeight: "50px" }}
          >
            SnoezEnfance®
          </Typography>
          La démarche Snoezelen pensée pour les plus petits !
        </Typography>
      </motion.div>
    </Banner>
  );
};

export default HomeHeader;
