import { Box, Typography, styled } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import SectionWithImage from "../common/SectionWithImage";
import Concepts from "./Concepts";
import WhitePaper from "./WhitePaper";

const VideoResponsive = styled("div")`
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    border: none;
  }
`;

const ConceptsBody = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Concepts />

      {/* <SectionWithImage
        image="/static/images/C1.jpg"
        content={
          <>
            <Typography>{t("concepts.1")}</Typography>
            <Typography>{t("concepts.2")}</Typography>
          </>
        }
      />
      <SectionWithImage
        image="/static/images/C2.jpg"
        content={
          <>
            <Typography>{t("concepts.3")}</Typography>
            <Typography>{t("concepts.4")}</Typography>
          </>
        }
      /> */}

      {/* <SectionWithImage
        image="static/bebe communicant.jpg"
        title={t("concepts.5_title")}
        content={
          <>
            <Typography>{t("concepts.5_title")}</Typography>
            <Typography>{t("concepts.3")}</Typography>
            <Typography>{t("concepts.4")}</Typography>
          </>
        }
      /> */}
      {/* <WhitePaper /> */}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: 3,
          pb: 3,
          mb: 3,
          textAlign: "center",
          background: "#f8f8f8",
        }}
      >
        <Typography variant="h3" sx={{ mb: 2 }}>
          {t("concepts.5_title")}
        </Typography>

        <Box
          sx={{
            width: "60%",
            overflow: "hidden",
            borderRadius: "10px",
            "@media screen and (max-width: 500px)": {
              width: "320px",
            },
          }}
        >
          <VideoResponsive>
            <iframe
              width="auto"
              height="auto"
              src="https://www.youtube.com/embed/WIw253ny_IQ"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </VideoResponsive>
        </Box>
      </Box>

      {/* <SectionWithImage
        image="/static/mains nb.jpg"
        title={
          <>
            L’accompagnement Sensori- Émotionnel® ,
            <br />
            <Typography sx={{ mt: 1 }}>
              Est un outil de décodage du langage spécifique de l’enfant, pour
              mieux l’observer, mieux s’ajuster et mieux répondre à ses besoins
              fondamentaux.
            </Typography>
          </>
        }
        content={
          <>
            <Typography>{t("concepts.5_title")}</Typography>
            <Typography>{t("concepts.3")}</Typography>
            <Typography>{t("concepts.4")}</Typography>
          </>
        }
        subContent={
          <>
            <Typography>{t("concepts.5_title")}</Typography>
            <Typography>{t("concepts.3")}</Typography>
          </>
        }
      /> */}
    </div>
  );
};

export default ConceptsBody;
