import { Container, Grid, styled, Typography } from "@mui/material";
import React from "react";

const BaseGrid = ({ children, ...props }: any) => (
  <Grid item lg={6} xl={6} md={6} sm={12} xs={12} {...props}>
    {children}
  </Grid>
);

const GridItem = styled(BaseGrid)`
  width: 100%;
  position: relative;
  /* &:nth-of-type(5) {
    @media screen and (max-width: 899px) {
      display: none;
    }
  } */
  &[data-type="image"] {
    background: rgba(0, 0, 0, 0.1);
    min-height: 420px;
  }
  &[data-type="text"] {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    min-height: 500px;
  }
`;

const BaseTitle = ({ children, ...props }: any) => (
  <Typography {...props} variant="h3">
    {children}
  </Typography>
);

const STitle = styled(BaseTitle)``;

const BaseParagraph = ({ children, ...props }: any) => (
  <Typography {...props}>{children}</Typography>
);

const SParagraph = styled(BaseParagraph)`
  margin-top: 20px;
  max-width: 55ch;
`;

const GridTextContent = styled("div")``;

const iconSize = 70;

const IconsContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  margin: 10px 0px;
`;

const Icon = styled("img")`
  /* border: 1px solid; */
  width: ${iconSize}px;
  /* height: ${iconSize}px; */
`;

const HomePres = () => {
  return (
    <Container>
      {/* 1 */}
      <Grid container>
        <GridItem
          data-type="image"
          order={{ xs: 2, md: 1 }}
          style={{
            backgroundImage: "url('/static/images/A1.jpg')",
            backgroundPosition: "top",
            backgroundSize: "cover",
          }}
        ></GridItem>
        <GridItem data-type="text" order={{ xs: 1, md: 2 }}>
          <GridTextContent>
            <STitle>SnoezEnfance®</STitle>
            <SParagraph>
              C’est la traduction que nous faisons pour l’enfant de l’approche
              Snoezelen initialement développée pour des adultes en situation de
              handicap.
            </SParagraph>
            <SParagraph>
              C’est une démarche d’accompagnement qui permet d’établir une
              communication sensorielle, émotionnelle et principalement
              non-verbale avec l’enfant dans un espace sécurisant favorable à la
              détente et au plaisir d’être ensemble.
            </SParagraph>
            <SParagraph>
              C’est un outil médiateur qui accompagne les besoins très
              spécifiques de l’enfant et aide les accompagnants à développer
              leur savoir être par une qualité de présence essentielle à la
              relation à soi et à autrui.
            </SParagraph>
          </GridTextContent>
        </GridItem>

        {/* 2 */}
        <GridItem data-type="text" order={{ xs: 3, md: 3 }}>
          <GridTextContent>
            <STitle>Pour des pratiques bientraitantes !</STitle>
            <SParagraph>
              Bien au-delà de l’éveil sensoriel, l’accompagnement sensori
              émotionnel® est un véritable outil pédagogique pour soutenir les
              équipes dans leurs pratiques et leurs postures professionnelles au
              quotidien !
            </SParagraph>
            <SParagraph>
              Nous avons créé de nombreux supports pour les professionnels pour
              accompagner l’enfant dans les différentes dimensions de son
              développement avec un nouveau regard.
            </SParagraph>
          </GridTextContent>
        </GridItem>
        <GridItem
          data-type="image"
          order={{ xs: 4, md: 4 }}
          style={{
            backgroundImage: "url('/static/images/A2.jpg')",
            backgroundPosition: "top",
            backgroundSize: "cover",
          }}
        ></GridItem>
        {/* 3 */}
        <GridItem
          data-type="image"
          order={{ xs: 6, md: 5 }}
          sx={{ xs: { display: "none!important", color: "red" } }}
          style={{
            backgroundImage: "url('/static/images/A3.jpg')",
            backgroundPosition: "top",
            backgroundSize: "cover",
          }}
        ></GridItem>
        <GridItem data-type="text" order={{ xs: 5, md: 6 }}>
          <GridTextContent>
            <STitle>Un outil adapté</STitle>
            <SParagraph>
              Nous avons créé de nombreux supports pour les professionnels pour
              accompagner l’enfant dans les différentes dimensions de son
              développement avec un nouveau regard. <br />
              Cet outil permet de reconnaitre ses différents besoins sensoriels,
              émotionnels et relationnels.
            </SParagraph>

            <IconsContainer>
              <Icon src="/static/icons/icon1.png" />
              <Icon src="/static/icons/icon2.png" />
              <Icon src="/static/icons/icon3.png" />
            </IconsContainer>
            {/* <p>Icons</p> */}
            <SParagraph>
              Il permet d’observer, d’analyser et d’ajuster son accompagnement
              dans une interaction bienveillante et nourrissante du bien grandir
              !
            </SParagraph>
          </GridTextContent>
        </GridItem>
      </Grid>
    </Container>
  );
};

export default HomePres;
