import { Typography } from "@mui/material";
import React from "react";
import Banner from "../components/common/Banner";
import { motion } from "framer-motion";
import Intro from "../components/concepts/Intro";
import ConceptsBody from "../components/concepts/ConceptsBody";
import Page from "../components/common/Page";

const ConceptsPage = () => {
  return (
    <Page title="page_titles.concepts">
      <Banner cover image="/static/header.jpg">
        <motion.div
          animate={{ scale: [0, 1], opacity: [0, 1] }}
          transition={{ duration: 0.5 }}
        >
          <Typography fontFamily="Grand Hotel" variant="h1" fontWeight="400">
            Concept
          </Typography>
        </motion.div>
      </Banner>
      <Intro />
      <ConceptsBody />
    </Page>
  );
};

export default ConceptsPage;
