import { styled, Typography, Container } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import SectionWithImage from "../SectionWithImage";

const FormationsFor = () => {
  const { t } = useTranslation();
  const list: string[] = [
    t("formations.3_list.0"),
    t("formations.3_list.1"),
    t("formations.3_list.2"),
    t("formations.3_list.3"),
  ];
  return (
    <Container sx={{ paddingTop: "50px", paddingBottom: "50px" }}>
      <Typography textAlign="center" variant="h3">
        {t("formations.for")}
      </Typography>
      {/* <SectionWithImage
        image="/static/espace creche playmobil.PNG"
        content={
          <>
            <Typography>{t("concepts.3")}</Typography>
            <Typography>{t("concepts.4")}</Typography>
          </>
        }
      /> */}
      <Typography sx={{ mt: 1 }}>{t("formations.3_1")}</Typography>
      <ul>
        {list.map((text: string) => (
          <li key={text}>
            <Typography>{text}</Typography>
          </li>
        ))}
      </ul>
      <Sections />
    </Container>
  );
};

export default FormationsFor;

const SectionsRoot = styled("div")`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;
const Sections = () => {
  const { t } = useTranslation();
  const generateList = (key: string): string[] => {
    const list: string[] = [];
    for (let i = 0; i < 3; i++) {
      list.push(t(`formations.${key}.${i}`));
    }
    return list;
  };

  const generateSection = (key: number) => {
    return (
      <>
        <Typography fontWeight="bold">
          {t(`formations.4_${key}.title`)}
        </Typography>
        <ul>
          {generateList(`4_${key}`).map((text: string, index: number) => (
            <li key={text + index}>
              <Typography>{text}</Typography>
            </li>
          ))}
        </ul>
        {/* <Typography>{t(`formations.4_${key}.text`)}</Typography> */}
      </>
    );
  };

  return (
    <SectionsRoot>
      {[1, 2].map((key: number) => (
        <div key={key}>{generateSection(key)}</div>
      ))}
    </SectionsRoot>
  );
};
