import fr from "../locales/fr.json";
import i18next from "i18next";

// Add supported locales here
const locales = ["fr"];

const nav = navigator as any;
let lng: any = (
  localStorage.getItem("lng") ||
  nav.language ||
  nav["userLanguage"] ||
  "fr"
).split("-")[0];

if (!locales.includes(lng)) lng = locales[0];

if (!localStorage.getItem("lng")) localStorage.setItem("lng", lng);

const resources = {
  fr: {
    translation: fr,
  },
};

export const i18nInit = {
  interpolation: { escapeValue: false },
  lng,
  resources,
};

export const initi18n = () => {
  i18next.init(i18nInit);
};
