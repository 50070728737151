import { ThemeProvider } from "@mui/material";
import { StyledEngineProvider, CssBaseline, createTheme } from "@mui/material";
import React from "react";
import componentsOverride from "./overrides";
import typography from "./typography";

const ThemeConfig: React.FC<any> = ({ children }) => {
  const theme = createTheme({ typography });

  theme.components = { ...componentsOverride(theme) };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default ThemeConfig;
