import { ButtonBase, styled, Typography } from "@mui/material";
import React from "react";

const Button = styled(ButtonBase)`
  padding: 35px;
  border-radius: 12px;
  background: #7fd7a7;
  width: 210px;
  height: 170px;
  margin: 30px;
  color: #fff;
`;

const StickerButton: React.FC<any> = ({ text, color, onClick }) => {
  return (
    <Button onClick={onClick} sx={{ backgroundColor: color }}>
      <Typography>{text}</Typography>
    </Button>
  );
};

export default StickerButton;
