import { Container, Typography, styled } from "@mui/material";
import React from "react";
import Page from "../components/common/Page";
import LegalHeader from "../components/LegalHeader";

const Section = styled("div")`
  margin-top: 20px;
`;

const SContainer = styled(Container)`
  margin-top: 20px;
  margin-bottom: 40px;
`;

const Legal = () => {
  return (
    <Page title="page_titles.legal">
      <LegalHeader />
      <SContainer>
        <Section>
          {/* <Typography fontWeight="bold">Mentions légales</Typography> */}
          <Typography>
            La navigation sur ce site est soumise aux présentes conditions
            d’utilisation. En accédant aux informations mises à disposition par
            Eveil et Signes – Isabelle Cottenceau sur son site Internet, vous
            reconnaissez avoir pris connaissance des présentes conditions
            d’utilisation et les accepter.
          </Typography>
        </Section>

        <Section>
          <Typography fontWeight="bold">
            Éditeur et propriétaire du site
          </Typography>
          <Typography>
            Ce site est édité par Eveil et Signes – Isabelle Cottenceau,
            Entreprise individuelle, enregistrée auprès de l’URSSAF du
            Maine-et-Loire sous le nom de « Isabelle Cottenceau », dont le siège
            est situé au 18B route de Cholet 49610 Mûrs-Erigné – Tel : 06 23 43
            89 03. ​
          </Typography>
        </Section>

        <Section>
          <Typography fontWeight="bold">Loi applicable</Typography>
          <Typography>
            Le contenu de ce site est régi par la loi française.
          </Typography>
          <Typography>Données personnelles</Typography>
          <Typography>
            Les données collectées par le biais du site sont destinées
            exclusivement à Eveil et Signes – Isabelle Cottenceau.
          </Typography>
          <Typography>
            Vous pouvez à tout moment exercer votre droit d’accès, d’opposition,
            de retrait et de rectification de données en adressant votre demande
            par écrit auprès d’Eveil et Signes, à l’adresse suivante : 18B route
            de Cholet – 49610 Mûrs-Erigné.
          </Typography>
        </Section>

        <Section>
          <Typography fontWeight="bold">Cookies</Typography>
          <Typography>
            Des données non personnelles sont collectées lors de votre
            navigation, par un système de cookies, à des fins statistiques. Ces
            fichiers stockés sur votre ordinateur permettent à Eveil et Signes –
            Isabelle Cottenceau d’enregistrer les informations relatives à votre
            navigation sur ce site (pages consultées, date et l’heure de
            consultation,…).
          </Typography>
          <Typography>
            En aucun cas ces données ne nous permettent de vous identifier,
            elles ont pour seul but de nous aider à vous offrir un accès
            facilité aux services proposés et à améliorer ce site. Vous pouvez
            vous opposer à l’enregistrement de cookies en configurant votre
            navigateur selon les modalités détaillées sur le site de la
            Commission Nationale Informatique et Libertés (www.cnil.fr).
          </Typography>
        </Section>

        <Section>
          <Typography fontWeight="bold">Contenu du site</Typography>
          <Typography>
            Eveil et Signes – Isabelle Cottenceau se réserve le droit de
            modifier, mettre à jour, corriger ou supprimer tout ou partie du
            contenu du site ainsi que des présentes conditions d’utilisation à
            tout moment et sans préavis. Eveil et Signes – Isabelle Cottenceau
            ne peut garantir l’exactitude, la précision, l’actualisation ou
            l’exhaustivité des informations mises à disposition sur ce site.
          </Typography>
          <Typography>
            En conséquence, Eveil et Signes décline toute responsabilité pour
            les éventuelles imprécisions, inexactitudes ou omissions portant sur
            des informations disponibles sur ce site.
          </Typography>
        </Section>

        <Section>
          <Typography fontWeight="bold">Propriété intellectuelle</Typography>
          <Typography>
            Toute représentation ou reproduction, même partielle, qui pourrait
            être faite du site ou des éléments le composant sans le consentement
            préalable et écrit de Eveil et Signes – Isabelle Cottenceau est
            illicite et susceptible de constituer un acte de contrefaçon
            engageant les responsabilités civile et pénale du contrefacteur.
          </Typography>
        </Section>

        <Section>
          <Typography fontWeight="bold">Liens hypertextes</Typography>
          <Typography>
            Les liens hypertextes mis en place dans le cadre du présent site
            Internet en direction d’autres sites présents sur le réseau
            Internet, ne sauraient engager la responsabilité d’Eveil et Signes –
            Isabelle Cottenceau en ce qui concerne leur contenu ou les liens
            qu’ils contiennent, ainsi que leurs conditions d’exploitation
            (notamment gestion des données personnelles,…).
          </Typography>
          <Typography>
            La possibilité d’établir des liens hypertextes simples vers ce site
            est soumise à l’accord préalable d’Eveil et Signes – Isabelle
            Cottenceau. Tout lien hypertexte profond, ou utilisant la technique
            du « framing » (affichage d’une page dans un cadre au sein d’un
            autre site), est interdit.
          </Typography>
        </Section>

        <Section>
          <Typography fontWeight="bold">
            Exonération de la responsabilité technique
          </Typography>
          <Typography>
            Eveil et Signes – Isabelle Cottenceau s’engage à produire leurs
            meilleurs efforts pour que ce site soit accessible à tout moment.
            Néanmoins, Eveil et Signes – Isabelle Cottenceau décline toute
            responsabilité en cas de difficulté d’accès à ce site ou
            d’interruptions dans la connexion, quelles qu’en soient les causes.
          </Typography>
          <Typography>
            Notamment, Eveil et Signes – Isabelle Cottenceau se réserve le droit
            de procéder à toute modification du site qu’elles jugera utile, et
            cela sans avertissement préalable et même si l’accès au site en est
            conséquemment interrompu.
          </Typography>
          <Typography>
            De plus, Eveil et Signes – Isabelle Cottenceau ne saurait être tenue
            responsable des éventuels dommages directs ou indirects, y compris
            l’inaccessibilité au site, les pertes de données, détériorations,
            destructions ou virus qui pourraient affecter votre équipement
            informatique, et/ou de la présence de virus sur son site. Il
            appartient aux internautes de prendre toutes les mesures appropriées
            pour la protection de leur matériel, données ou logiciels
            informatiques notamment contre les virus informatiques.
          </Typography>
          <Typography>
            Vous reconnaissez avoir vérifié que la configuration informatique
            utilisée ne contient aucun virus et est en parfait état de
            fonctionnement.
          </Typography>
        </Section>

        <Section>
          <Typography fontWeight="bold">Crédits</Typography>
          <Typography>
            Les mentions légales ont été générées et offertes par Wix Website.
          </Typography>
        </Section>

        <Section>
          <Typography fontWeight="bold">Crédits photos et vidéos</Typography>
          <Typography>
            https://stock.adobe.com/fr/ , Wix Website Editor et vidéo formation:
            Clara Desse - 78100 St Germain-en-Laye (Siret: 83265871000013)
          </Typography>
        </Section>

        <Section>
          <Typography fontWeight="bold">Hébergeur</Typography>
          <Typography>
            Ce site internet est créé sur Wix Website Editor et hébergé sur
            Nework Solutions, LLC : abuse@web.com .
          </Typography>
        </Section>
      </SContainer>
    </Page>
  );
};

export default Legal;
