import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import HomeFormationsDiscover from "./HomeFormationsDiscover";
import HomeFormationsIntro from "./HomeFormationsIntro";
import HomeNextFormations from "./HomeNextFormations";

const HomeFormations = () => {
  const { t } = useTranslation();
  return (
    <Box
      component="section"
      sx={{ paddingTop: { md: 10, xs: 4, sm: 4 }, paddingBottom: 4 }}
    >
      <Typography
        textAlign="center"
        fontFamily="Grand Hotel"
        variant="h3"
        fontWeight="400"
      >
        {t("homepage.subtitle_2")}
      </Typography>

      <HomeFormationsIntro />

      <HomeFormationsDiscover />

      {/* <HomeNextFormations /> */}
    </Box>
  );
};

export default HomeFormations;
