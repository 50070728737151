import { Container, Stack, styled, Typography } from "@mui/material";
import React from "react";

const Content = styled("div")`
  display: flex;
  padding: 50px 0px;
  text-align: left;
  align-items: center;
`;

const Root = styled("div")`
  :nth-of-type(even) {
    background: #f8f8f8; // ! palette
    .content {
      flex-direction: row-reverse;
      .content-prop {
        margin-right: 20px;
      }
    }

    @media screen and (max-width: 900px) {
      .content {
        flex-direction: column;
        .content-prop {
          margin-right: 0;
        }
      }
    }
  }
  .content-prop {
    margin-left: 20px;
  }
  @media screen and (max-width: 900px) {
    .content {
      flex-direction: column;
      .content-prop {
        margin-left: 0;
      }
      .img {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
      }
    }
  }
`;

const Image = styled("div")`
  width: 300px;
  min-width: 300px;
  height: 300px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

const SectionWithImage: React.FC<{
  title?: any;
  subContent?: any;
  content?: any;
  image?: string;
}> = ({ title, subContent, image, content }) => {
  return (
    <Root>
      <Container>
        {title && (
          <Typography
            paddingTop="50px"
            variant="h4"
            sx={{ textAlign: "center", mb: 3 }}
          >
            {title}
          </Typography>
        )}
        <Content
          sx={{
            paddingTop: title ? "0px" : "50px",
            paddingBottom: subContent ? "0px" : "50px",
          }}
          className="content"
        >
          <Image
            style={{ backgroundImage: `url("${image}")` }}
            className="img"
          />
          <Stack spacing={2} className="content-prop">
            {content}
          </Stack>
        </Content>
        <div style={{ paddingBottom: subContent ? "50px" : "0px" }}>
          {subContent}
        </div>
      </Container>
    </Root>
  );
};

export default SectionWithImage;
