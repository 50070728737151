import { styled } from "@mui/material";
import React from "react";
import Footer from "./components/layout/Footer";
import Navbar from "./components/layout/navbar/Navbar";
import GuestRoutes from "./routes/GuestRoutes";

const Header = styled("header")`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  text-align: center;
`;

const Root = styled("div")`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Main = styled("main")`
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
`;

function App() {
  return (
    <Root>
      <Header>
        <Navbar />
      </Header>
      <Main>
        <GuestRoutes />
      </Main>
      <Footer />
    </Root>
  );
}

export default App;
