interface pathes {
  [locale: string]: routes;
}

interface routes {
  home: string;
  concepts: string;
  not_found: string;
  presentation: string;
  formations: string;
  contact: string;
  policy: string;
  legal: string;
}

const guestPathes: pathes = {
  fr: {
    home: "accueil",
    concepts: "concepts",
    not_found: "404",
    presentation: "presentation",
    formations: "formations",
    contact: "contact",
    policy: "policy",
    legal: "legal",
  },
};

export default guestPathes;
