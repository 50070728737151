import React from "react";

import { motion } from "framer-motion";
import Banner from "../Banner";
import { Typography } from "@mui/material";

const PresentationHeader = () => {
  return (
    <Banner cover image="/static/header.jpg">
      <motion.div
        animate={{ scale: [0, 1], opacity: [0, 1] }}
        transition={{ duration: 0.5 }}
      >
        <Typography fontFamily="Grand Hotel" variant="h1" fontWeight="400">
          Présentation
        </Typography>
      </motion.div>
    </Banner>
  );
};

export default PresentationHeader;
