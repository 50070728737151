import { styled } from "@mui/material";
import React from "react";

const Root = styled("div")`
  width: 100%;
  height: 450px;
  background-image: url("/static/banner.jpg");
  background-position: center center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  position: relative;
  @media screen and (max-width: 500px) {
    height: 300px;
  }
`;

const Overlay = styled("div")`
  background: #3e3b3bd4;
  opacity: 0.85;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const TextContainer = styled("div")`
  padding: 14px;
  z-index: 2;
`;

const Banner: React.FC<any> = ({ children, image, cover }) => {
  return (
    <Root
      style={{
        backgroundImage: `url("${image}")`,
        ...(cover ? { backgroundSize: "cover" } : {}),
      }}
    >
      <TextContainer>{children}</TextContainer>
      <Overlay />
    </Root>
  );
};

export default Banner;
