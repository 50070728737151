import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import { forwardRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
// material

// ----------------------------------------------------------------------

const Page = forwardRef(
  ({ children, title = "", padding, sx, className, ...other }: any, ref) => {
    const { t } = useTranslation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    return (
      <div className={className} ref={ref} {...other}>
        <Helmet>
          <title>{t(title)}</title>
        </Helmet>
        {children}
      </div>
    );
  }
);

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default Page;
