import { Container, Typography, styled } from "@mui/material";
import React from "react";
import Page from "../components/common/Page";
import PolicyHeader from "../components/PolicyHeader";

const Section = styled("div")`
  margin-top: 20px;
`;

const SContainer = styled(Container)`
  margin-top: 20px;
  margin-bottom: 40px;
`;

const Policy = () => {
  return (
    <Page title="page_titles.policy">
      <PolicyHeader />
      <SContainer>
        {/* <Typography variant="h3">POLITIQUE DE CONFIDENTIALITÉ</Typography> */}
        <Section>
          <Typography variant="h4">1.Collecte de l’information</Typography>
          <Typography>
            Nous recueillons des informations lorsque vous vous inscrivez sur
            notre site, lorsque vous vous connectez à votre compte, faites un
            achat, participez à un concours, et / ou lorsque vous vous
            déconnectez. Les informations recueillies incluent votre nom, votre
            adresse e-mail, numéro de téléphone, et / ou carte de crédit. En
            outre, nous recevons et enregistrons automatiquement des
            informations à partir de votre ordinateur et navigateur, y compris
            votre adresse IP, vos logiciels et votre matériel, et la page que
            vous demandez. ​
          </Typography>
        </Section>
        <Section>
          <Typography variant="h4">2. Utilisation des informations</Typography>
          <Typography>
            Toutes les informations que nous recueillons auprès de vous peuvent
            être utilisées pour :
          </Typography>
          <ul>
            <li>
              <Typography>
                Personnaliser votre expérience et répondre à vos besoins
                individuels
              </Typography>
            </li>
            <li>
              <Typography>
                Fournir un contenu publicitaire personnalisé
              </Typography>
            </li>
            <li>
              <Typography>Améliorer notre site</Typography>
            </li>
            <li>
              <Typography>
                Améliorer le service client et vos besoins de prise en charge
              </Typography>
            </li>
            <li>
              <Typography>Vous contacter par e-mail</Typography>
            </li>
            <li>
              <Typography>
                Administrer un concours, une promotion, ou une enquête
              </Typography>
            </li>
          </ul>
        </Section>
        <Section>
          <Typography variant="h4">
            3. Confidentialité du commerce en ligne
          </Typography>
          <Typography>
            Nous sommes les seuls propriétaires des informations recueillies sur
            ce site. Vos informations personnelles ne seront pas vendues,
            échangées, transférées, ou données à une autre société pour
            n’importe quelle raison, sans votre consentement, en dehors de ce
            qui est nécessaire pour répondre à une demande et / ou une
            transaction, comme par exemple pour expédier une commande.
          </Typography>
        </Section>
        <Section>
          <Typography variant="h4">4. Divulgation à des tiers</Typography>
          <Typography>
            Nous ne vendons, n’échangeons et ne transférons pas vos informations
            personnelles identifiables à des tiers. Cela ne comprend pas les
            tierce parties de confiance qui nous aident à exploiter notre site
            Web ou à mener nos affaires, tant que ces parties conviennent de
            garder ces informations confidentielles. Nous pensons qu’il est
            nécessaire de partager des informations afin d’enquêter, de prévenir
            ou de prendre des mesures concernant des activités illégales,
            fraudes présumées, situations impliquant des menaces potentielles à
            la sécurité physique de toute personne, violations de nos conditions
            d’utilisation, ou quand la loi nous y contraint. Les informations
            non-privées, cependant, peuvent être fournies à d’autres parties
            pour le marketing, la publicité, ou d’autres utilisations.
          </Typography>
        </Section>
        <Section>
          <Typography variant="h4">5. Protection des informations</Typography>
          <Typography>
            Nous mettons en œuvre une variété de mesures de sécurité pour
            préserver la sécurité de vos informations personnelles. Nous
            utilisons un cryptage à la pointe de la technologie pour protéger
            les informations sensibles transmises en ligne. Nous protégeons
            également vos informations hors ligne. Seuls les employés qui ont
            besoin d’effectuer un travail spécifique (par exemple, la
            facturation ou le service à la clientèle) ont accès aux informations
            personnelles identifiables. Les ordinateurs et serveurs utilisés
            pour stocker des informations personnelles identifiables sont
            conservés dans un environnement sécurisé.
          </Typography>
          <Typography>
            Nos cookies améliorent l’accès à notre site et identifient les
            visiteurs réguliers. En outre, nos cookies améliorent l’expérience
            d’utilisateur grâce au suivi et au ciblage de ses intérêts.
            Cependant, cette utilisation des cookies n’est en aucune façon liée
            à des informations personnelles identifiables sur notre site.
          </Typography>
        </Section>
        <Section>
          <Typography variant="h4">6. Se désabonner</Typography>
          <Typography>
            Nous utilisons l’adresse e-mail que vous fournissez pour vous
            envoyer des informations et mises à jour relatives à votre commande,
            des nouvelles de l’entreprise de façon occasionnelle, des
            informations sur des produits liés, etc. Si à n’importe quel moment
            vous souhaitez vous désinscrire et ne plus recevoir d’e-mails, des
            instructions de désabonnement détaillées sont incluses en bas de
            chaque e-mail.
          </Typography>
        </Section>
        <Section>
          <Typography variant="h4">7. Accès à vos données</Typography>
          <Typography>
            Vous avez la possibilité de demander une copie de vos données
            traitées ou la suppression pure de vos données. Un délai de 48h est
            à prévoir entre votre demande et la réalisation de la demande. ​
          </Typography>
          <Typography>
            Cliquez ici si vous désirez demander une copie ou la surpression de
            vos données. ​
          </Typography>
        </Section>
        <Section>
          <Typography variant="h4">8. Consentement</Typography>
          <Typography>
            En utilisant notre site, vous consentez à notre politique de
            confidentialité. ​
          </Typography>
        </Section>
      </SContainer>
    </Page>
  );
};

export default Policy;
