import { Box } from "@mui/material";
import React from "react";
import StickerButton from "../../common/StickerButton";
import { useNavigate } from "react-router-dom";

const HomeFormationsDiscover = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/formations");
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      flexWrap="wrap"
      sx={{ background: "#EDEDED" }}
    >
      <StickerButton
        onClick={handleClick}
        color="#7325D6"
        text="Découverte de la méthode Snoezelen"
      />
      <StickerButton
        onClick={handleClick}
        color="#2826CE"
        text="Initiation à la démarche Snoezelen en Petite Enfance"
      />
      <StickerButton
        onClick={handleClick}
        color="#2769C6"
        text="SnoezEnfance, formations inter établissements"
      />
    </Box>
  );
};

export default HomeFormationsDiscover;
