import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { IconButton, styled } from "@mui/material";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import { useNavigate } from "react-router-dom";
import PsychologyRoundedIcon from "@mui/icons-material/PsychologyRounded";
import CoPresentRoundedIcon from "@mui/icons-material/CoPresentRounded";
import AssuredWorkloadRoundedIcon from "@mui/icons-material/AssuredWorkloadRounded";
import PermContactCalendarRoundedIcon from "@mui/icons-material/PermContactCalendarRounded";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

type Anchor = "top" | "left" | "bottom" | "right";

export default function NavDrawer({ open, close }: any) {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const navigate = useNavigate();

  const links = [
    {
      label: "Accueil",
      path: "/accueil",
      icon: <HomeRoundedIcon />,
    },
    {
      label: "Concepts",
      path: "/concepts",
      icon: <PsychologyRoundedIcon />,
    },
    {
      label: "Présentation",
      path: "/presentation",
      icon: <CoPresentRoundedIcon />,
    },
    {
      label: "Formations",
      path: "/formations",
      icon: <AssuredWorkloadRoundedIcon />,
    },
    {
      label: "Contact",
      path: "/contact",
      icon: <PermContactCalendarRoundedIcon />,
    },
  ];

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const goTo = (path: string) => {
    navigate(path);
    close();
  };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {links.map((item: any, index) => (
          <ListItem key={item.path} disablePadding>
            <ListItemButton onClick={() => goTo(item.path)}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment>
        <Drawer anchor={"left"} open={open} onClose={close}>
          <DrawerHeader>
            <IconButton onClick={close}>
              <ChevronLeftIcon />
            </IconButton>
          </DrawerHeader>
          <Divider />
          {list("left")}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
