import Button from "./button";
import GlobalStyle from "./gloabStyle";
import Input from "./Input";

export default function ComponentsOverrides(theme: any) {
  return {
    ...Input(theme),
    ...GlobalStyle(theme),
    ...Button(theme),
  };
}
