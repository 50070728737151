import React from "react";
import FormationsBody from "../components/common/formations/FormationsBody";
import FormationsHeader from "../components/common/formations/FormationsHeader";
import Page from "../components/common/Page";

const FormationsPage = () => {
  return (
    <Page title="page_titles.formations">
      <FormationsHeader />
      <FormationsBody />
    </Page>
  );
};

export default FormationsPage;
