import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  styled,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Image = styled("div")(({ theme }) => ({
  borderTopLeftRadius: "25px",
  borderBottomLeftRadius: "25px",
  backgroundImage: "url('/static/images/A6.jpg')",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  width: "100%",
  height: "400px",
  minHeight: "400px",
  backgroundPosition: "top",
  [theme.breakpoints.down("md")]: {
    height: "300px",
    minHeight: "300px",
    borderTopLeftRadius: "0px",
    borderBottomRightRadius: "25px",
  },
}));

const MotionDiv = styled(motion.div)(({ theme }) => ({
  background: "#EDEDED",
  padding: "25px",
  height: "100%",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderTopRightRadius: "25px",
  borderBottomRightRadius: "25px",
  [theme.breakpoints.down("md")]: {
    borderBottomRightRadius: "0px",
    borderTopLeftRadius: "25px",
  },
}));

const HomeFormationsIntro = () => {
  const { t } = useTranslation();
  const animationDuration = 1;
  const largeScreen = useMediaQuery((theme: any) => theme.breakpoints.up("md"));

  const [textRef, textInView] = useInView({
    triggerOnce: true,
    rootMargin: "-100px 0px",
  });

  const [imgRef, imgInView] = useInView({
    triggerOnce: true,
    rootMargin: "-100px 0px",
  });

  return (
    <Box
      sx={{
        paddingTop: 4,
        paddingBottom: { md: 10, sm: 4, xs: 4 },
        overflow: "hidden",
      }}
    >
      <Container>
        <Grid
          container
          direction={largeScreen ? "row" : "column-reverse"}
          sx={{ minHeight: "400px" }}
        >
          <Grid
            item
            md={6}
            sm={12}
            sx={{
              minHeight: { md: "400px", xs: "300px" },
            }}
            ref={imgRef}
          >
            <motion.div
              animate={{
                translateX: !imgInView ? `-${window.innerWidth}px` : "0px",
              }}
              transition={{ duration: animationDuration }}
            >
              <Image />
            </motion.div>
          </Grid>
          <Grid item md={6} sm={12} ref={textRef}>
            <MotionDiv
              animate={{
                translateX: !textInView ? `${window.innerWidth}px` : "0px",
              }}
              transition={{ duration: animationDuration }}
              // style={{
              //   padding: "10px",
              //   background: "#EDEDED",
              //   height: "100%",
              //   width: "100%",
              //   display: "flex",
              //   alignItems: "center",
              //   justifyContent: "center",
              // }}
            >
              <Stack spacing={1}>
                <Typography sx={{ maxWidth: "58ch" }}>
                  {t("homepage.text_7")}
                </Typography>
                <Typography sx={{ maxWidth: "58ch" }}>
                  {t("homepage.text_8")}
                </Typography>
                <Typography sx={{ maxWidth: "58ch" }}>
                  {t("homepage.text_9")}
                </Typography>
              </Stack>
            </MotionDiv>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default HomeFormationsIntro;
