import { Grid, Box, Typography, Container, Stack, styled } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const MotionDiv = styled("div")(({ theme }) => ({
  padding: "25px",
  background: "white",
  height: "100%",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderTopLeftRadius: "25px",
  borderBottomLeftRadius: "25px",
  [theme.breakpoints.down("md")]: {
    borderBottomLeftRadius: "0px",
    borderTopRightRadius: "25px",
  },
}));

const Image = styled("div")(({ theme }) => ({
  borderTopRightRadius: "25px",
  borderBottomRightRadius: "25px",
  backgroundImage: "url('/static/kid_2.jpg')",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  width: "100%",
  height: "100%",
  minHeight: "400px",
  backgroundPosition: "center",
  [theme.breakpoints.down("md")]: {
    height: "400px",
    minHeight: "300px",
    borderTopRightRadius: "0px",
    borderBottomLeftRadius: "25px",
  },
}));

const HomePresSecondPart: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        paddingTop: { md: 10, sm: 4, xs: 4 },
        paddingBottom: { md: 10, sm: 4, xs: 4 },
      }}
    >
      <Container>
        <Grid container sx={{ minHeight: "400px" }}>
          <Grid item md={6} sm={12}>
            <MotionDiv
              style={{
                display: "flex",
                justifyContent: " center",
                alignItems: "center",
                height: "100%",
                background: "#EDEDED",
              }}
            >
              <Box sx={{ padding: "10px" }}>
                <Stack spacing={1}>
                  <Typography variant="h3" sx={{ maxWidth: "58ch", mb: 1 }}>
                    {t("homepage.subtitle_1")}
                  </Typography>
                  <Typography sx={{ maxWidth: "58ch" }}>
                    {t("homepage.text_4")}
                  </Typography>
                  <Typography sx={{ maxWidth: "58ch" }}>
                    {t("homepage.text_4-2")}
                  </Typography>
                  <Typography sx={{ maxWidth: "58ch" }}>
                    {t("homepage.text_4-3")}
                  </Typography>
                  <Typography sx={{ maxWidth: "58ch" }}>
                    {t("homepage.text_5")}
                  </Typography>
                </Stack>
              </Box>
            </MotionDiv>
          </Grid>

          <Grid
            item
            md={6}
            sm={12}
            sx={{
              minHeight: { md: "400px", xs: "300px" },
              width: "100%",
            }}
          >
            <Image />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default HomePresSecondPart;
