import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";

import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";

import { NavLink, useNavigate } from "react-router-dom";

import NavDrawer from "../NavDrawer";
// import pages from "../../../utils/pages";
// import NavbarSocial from "./NavbarSocial";

const Navbar = () => {
  const [open, setOpen] = React.useState<boolean>(false);

  const navigate = useNavigate();

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(true);
  };

  const handleCloseNavMenu = () => {
    setOpen(false);
  };

  return (
    <AppBar
      position="static"
      color="default"
      sx={{ height: { md: "80px", xs: "56px", sm: "64px" } }}
    >
      <Container maxWidth="xl" sx={{ height: "100%" }}>
        <Toolbar sx={{ height: "100%" }} disableGutters>
          {/* <AdbIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} /> */}
          <Box
            component="img"
            alt="logo"
            src="/static/logo.png"
            sx={{ display: { xs: "none", md: "flex" }, mr: 1, height: "65px" }}
          />
          {/* <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            LOGO
          </Typography> */}

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box>
          {/* <AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} /> */}
          {/* <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            LOGO
          </Typography> */}
          <Box
            onClick={() => navigate("/accueil")}
            component="img"
            alt="logo"
            src="/static/logo.png"
            sx={{
              display: { xs: "inherit", md: "none" },
              mr: "auto",
              ml: "auto",
              height: "40px",
            }}
          />

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <NavLink to="/accueil" style={{ textDecoration: "none" }}>
              <Button
                onClick={handleCloseNavMenu}
                sx={{ my: 2, display: "block" }}
              >
                Accueil
              </Button>
            </NavLink>
            <NavLink to="/concepts" style={{ textDecoration: "none" }}>
              <Button
                onClick={handleCloseNavMenu}
                sx={{ my: 2, display: "block" }}
              >
                Concept
              </Button>
            </NavLink>

            <NavLink to="/presentation" style={{ textDecoration: "none" }}>
              <Button
                onClick={handleCloseNavMenu}
                sx={{ my: 2, display: "block" }}
              >
                Présentation
              </Button>
            </NavLink>
            <NavLink to="/formations" style={{ textDecoration: "none" }}>
              <Button
                onClick={handleCloseNavMenu}
                sx={{ my: 2, display: "block" }}
              >
                Formations
              </Button>
            </NavLink>
            {/* <NavLink to="/ressources" style={{ textDecoration: "none" }}>
              <Button
                onClick={handleCloseNavMenu}
                sx={{ my: 2, display: "block" }}
              >
                Ressources
              </Button>
            </NavLink> */}
            <NavLink to="/contact" style={{ textDecoration: "none" }}>
              <Button
                onClick={handleCloseNavMenu}
                sx={{ my: 2, display: "block" }}
              >
                Contact
              </Button>
            </NavLink>

            {/* {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                {page}
              </Button>
            ))} */}
          </Box>
          {/* <NavbarSocial /> */}
        </Toolbar>
      </Container>
      <NavDrawer open={open} close={handleCloseNavMenu} />
    </AppBar>
  );
};
export default Navbar;
