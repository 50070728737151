import { Container, Grid, Typography, styled } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const BaseGrid = ({ children, ...props }: any) => (
  <Grid item lg={6} xl={6} md={6} sm={12} xs={12} {...props}>
    {children}
  </Grid>
);

const GridItem = styled(BaseGrid)`
  width: 100%;
  position: relative;
  &[data-type="image"] {
    background: rgba(0, 0, 0, 0.1);
    min-height: 420px;
  }
  &[data-type="text"] {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    min-height: 500px;
  }
`;

const BaseTitle = ({ children, ...props }: any) => (
  <Typography {...props} variant="h3">
    {children}
  </Typography>
);

// const STitle = styled(BaseTitle)``;

const BaseParagraph = ({ children, ...props }: any) => (
  <Typography {...props}>{children}</Typography>
);

const SParagraph = styled(BaseParagraph)`
  margin-top: 20px;
  max-width: 55ch;
`;

const GridTextContent = styled("div")``;

const Concepts = () => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        paddingBottom: "50px",
        background: "#f8f8f8",
      }}
    >
      <Container>
        {/* 1 */}
        <Grid container>
          <GridItem
            data-type="image"
            order={{ xs: 2, md: 1 }}
            style={{
              backgroundImage: "url('/static/images/C1.jpg')",
              backgroundPosition: "top",
              backgroundSize: "cover",
            }}
          ></GridItem>
          <GridItem data-type="text" order={{ xs: 1, md: 2 }}>
            <GridTextContent>
              {/* <STitle>SnoezEnfance®</STitle> */}
              <SParagraph>{t("concepts.1")}</SParagraph>
              <SParagraph>{t("concepts.2")}</SParagraph>
            </GridTextContent>
          </GridItem>

          {/* 2 */}
          <GridItem data-type="text" order={{ xs: 3, md: 3 }}>
            <GridTextContent>
              {/* <STitle>Pour des pratiques bientraitantes !</STitle> */}
              <SParagraph>{t("concepts.3")}</SParagraph>
              <SParagraph>{t("concepts.4")}</SParagraph>
            </GridTextContent>
          </GridItem>
          <GridItem
            data-type="image"
            order={{ xs: 4, md: 4 }}
            style={{
              backgroundImage: "url('/static/images/C2.jpg')",
              backgroundPosition: "top",
              backgroundSize: "cover",
            }}
          ></GridItem>
        </Grid>
      </Container>
    </div>
  );
};

export default Concepts;
