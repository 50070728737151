import {
  styled,
  Button,
  Typography,
  ButtonBase,
  Collapse,
  Stack,
} from "@mui/material";
import React, { useState } from "react";

const Root = styled("div")`
  background: #f8f8f8; // ! palette
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 20px;
`;
const Card = styled("div")`
  background: white;
  padding: 50px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Text = styled(Typography)`
  max-width: 80ch;
`;

const SButton = styled(ButtonBase)`
  background: #9c25da;
  height: 60px;
  width: 200px;
  border-radius: 19px;
  color: white;
  font-size: 20px;
`;

const SLink = styled("a")`
  text-decoration: none;
`;

const Intro = () => {
  const [collapse, setCollapse] = useState(false);
  const handleClick = () => {
    setCollapse((c) => !c);
  };
  return (
    <Root>
      <Card>
        <Text fontWeight="normal">
          La démarche SnoezEnfance® est une approche relationnelle inspirée de
          la démarche globale qu’est la démarche Snoezelen. Initialement
          développée pour les adultes en situation de handicap, Snoezelen est
          une démarche qui a pour but de favoriser l’expérimentation, la
          perception corporelle et sensorielle, la relation et le bien-être pour
          les personnes qui pratiquent cette approche.
        </Text>
        <Text fontWeight="normal" sx={{ mt: 2 }}>
          Pour en savoir plus sur Snoezelen, c’est par ici !
        </Text>
        {/* <SLink href="https://isna-mse.org/" target="_blank" rel="noreferrer">
   
        </SLink> */}
        <SButton onClick={handleClick} sx={{ mt: 2 }}>
          En savoir plus
        </SButton>

        <Collapse timeout="auto" in={collapse}>
          <Stack sx={{ mt: 2 }} spacing={2}>
            <Typography variant="h3">Snoezelen en quelques mots…</Typography>
            <Text>
              Ce concept est né à la fin des années 70, aux Pays-Bas, au Centre
              Hartenberg à Ede qui accueillait des personnes en situation de
              polyhandicap mental sévère. Les fondateurs, Jan Hulsegge et Ad
              Verheul, qui travaillaient dans ce centre, ont cherché par
              différents moyens à favoriser les interactions et la communication
              des personnes avec leur environnement et les personnes qui les
              accompagnaient. C’est en proposant un environnement sensoriel
              spécifique qu’un nouvel langage a pu émerger chez certains d’entre
              eux, basé sur la communication non verbale, sorte de langage
              sensoriel, corporel et émotionnel plus adapté pour eux.
            </Text>
            <Text>
              Snoezelen n’est ni une méthode, ni une technique, mais avant tout
              une philosophie du « prendre soin ». Le Snoezelen® fait référence
              à une démarche d’accompagnement, à un mode d’approche, à une façon
              d’accompagner la personne et de la rencontrer là où elle en est
              dans son processus de développement quel qu’il soit. On évoque
              également l’espace ou de salle Snoezelen pour le lieu dédié, ou
              encore du matériel sensoriel Snoezelen.
              <br /> Le Snoezelen ne se définit pas, il se vit, il se sent dans
              la relation et l’intensité du moment présent. C’est avant tout un
              état d’esprit qui repose sur trois axes principaux :
            </Text>
            <ul>
              <li>
                <Text>L’importance du respect de la personne</Text>
              </li>
              <li>
                <Text>La priorité aux expériences sensorielles</Text>
              </li>
              <li>
                <Text>La recherche de la détente et de la satisfaction</Text>
              </li>
            </ul>

            <Text>
              C’est un accompagnement dans la globalité de la personne qui
              repose sur un triptyque: du matériel adapté, un environnement
              pensé et une posture ajustée de l’adulte accompagnant, lui-même
              formé à la démarche Snoezelen. Cela nécessite des connaissances
              approfondies de la personne, mais aussi de la démarche et ses
              valeurs, de ses principes et leur déclinaison pratique pour un
              accompagnement sécurisant et bienveillant.
              <br />
              Pour en savoir plus sur les valeurs de l’approche Snoezelen :
              <br />
              <Typography
                component="a"
                href="https://isna-mse.org/"
                target="_blank"
                rel="noreferrer"
              >
                https://isna-mse.org/
              </Typography>
            </Text>
          </Stack>
        </Collapse>
      </Card>
    </Root>
  );
};

export default Intro;
