import React from "react";
import Page from "../components/common/Page";
import PresentationBody from "../components/common/presentation/PresentationBody";
import PresentationHeader from "../components/common/presentation/PresentationHeader";

const PresentationPage = () => {
  return (
    <Page title="page_titles.presentation">
      <PresentationHeader />

      <PresentationBody />
    </Page>
  );
};

export default PresentationPage;
