import { styled, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
// import NavbarSocial from "./navbar/NavbarSocial";

const Root = styled("footer")`
  padding: 5px;
  padding-top: 20px;
  /* background: #ccc; */
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  text-align: center;
  position: relative;
`;

const StartDivider = styled("div")`
  width: 75%;
  height: 1px;
  background-color: #9c25da;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
`;

const SLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  &:visited {
    color: inherit;
  }
`;

const Footer: React.FC = () => {
  const year = new Date().getFullYear();

  return (
    <Root>
      <StartDivider />
      {/* <NavbarSocial /> */}
      <div>
        <Typography sx={{ mb: 2 }}>
          SnoezEnfance by RAINBOW AudiFormaConseil -{" "}
          <Typography
            sx={{ color: "#7325D6", textDecoration: "none" }}
            component="a"
            href="https://rainbowafc.com"
            target="_blank"
          >
            rainbowafc.com
          </Typography>
        </Typography>
      </div>
      <div>
        <Typography>
          Organisme de formation référencé certifié QUALIOPI
        </Typography>
        <Typography>
          La certification Qualité a été délivrée au titre de la catégorie :
          actions de formation
        </Typography>
        <img
          style={{ width: "150px" }}
          src="/static/qualiopi.png"
          alt="Qualiopi logo"
        />
      </div>

      <SLink to="/contact">Contact</SLink>
      <Typography sx={{ mb: 2, mt: 2 }}>
        <SLink to="/legal">Mentions légales</SLink> |{" "}
        <SLink to="/policy">Politique de confidentialité</SLink>
        {/*  Conditions d'utilisation | Politique des cookies */}
      </Typography>
      <Typography>
        Copyright&copy; 2021-{year}. Tous droits réservés.
      </Typography>
    </Root>
  );
};

export default Footer;
