import { Container, styled, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import SectionWithImage from "../SectionWithImage";
import Bios from "./Bios";

const PresentationBody = () => {
  return (
    <>
      <Intro />
      <Apple />
      <Bios />
    </>
  );
};

export default PresentationBody;

const Intro = () => {
  const { t } = useTranslation();
  return (
    <div>
      <SectionWithImage
        image="/static/LOGO SNOEZENFANCE COMPLET END.png"
        content={
          <>
            <Typography>{t("concepts.1")}</Typography>
            <Typography>{t("concepts.2")}</Typography>
            <Typography fontWeight="bold">{t("concepts.2_5")}</Typography>
          </>
        }
      />
      {/* <SectionWithImage
        image="/static/1000 JOURS B.jpg"
        content={
          <>
            <Typography>{t("concepts.3")}</Typography>
            <Typography>{t("concepts.4")}</Typography>
          </>
        }
      /> */}
    </div>
  );
};

const AppleImg = styled("div")`
  width: 300px;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  background-image: url("/static/pomme.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;
const AppleRoot = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0px;
  background: #f8f8f8;
`;
const Apple = () => {
  const { t } = useTranslation();
  return (
    <AppleRoot>
      <Container>
        <AppleImg />
        <div>
          <Typography>{t("presentation_page.3_1")}</Typography>
          <Typography>{t("presentation_page.3_2")}</Typography>
        </div>
      </Container>
    </AppleRoot>
  );
};
