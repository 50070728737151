import { Typography, Container, Box } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import HomeFormationsDiscover from "../../home/formations/HomeFormationsDiscover";
import SectionWithImage from "../SectionWithImage";
import FormationsFor from "./FormationsFor";

const FormationsBody = () => {
  const { t } = useTranslation();
  return (
    <div>
      <SectionWithImage
        image="/static/images/F1.jpg"
        content={
          <>
            <Typography>{t("formations.1_1")}</Typography>
            <Typography>{t("formations.1_2")}</Typography>
            <Typography>{t("formations.1_3")}</Typography>
          </>
        }
      />
      <SectionWithImage
        image="/static/questions-1328465_1920.png"
        content={
          <>
            <Typography>{t("formations.2_1")}</Typography>
            <Typography>{t("formations.2_2")}</Typography>
          </>
        }
      />
      <FormationsFor />

      <HomeFormationsDiscover />

      <Container sx={{ mb: 5, mt: 3 }}>
        <Typography>{t("formations.5_title")}</Typography>
        <Typography>{t("formations.5_subtitle")}</Typography>
        <Typography dangerouslySetInnerHTML={{ __html: t("formations.5_1") }} />
        <Box>
          <Typography
            sx={{ mt: 1, fontWeight: "bold", display: "inline-block", mr: 1 }}
          >
            {t("formations.5_2")}
          </Typography>
          <Typography
            sx={{ display: "inline-block" }}
            component="a"
            href="mailto:contact@rainbowafc.com"
          >
            contact@rainbowafc.com
          </Typography>
        </Box>
      </Container>
    </div>
  );
};

export default FormationsBody;
