import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import Banner from "../../common/Banner";

import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const HomeInterSections: React.FC = () => {
  const { t } = useTranslation();

  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: "-150px 0px",
  });

  return (
    <Banner image="/static/banner.jpg">
      <motion.div
        animate={{ scale: inView ? 1 : 0, opacity: inView ? 1 : 0 }}
        transition={{ duration: 0.5 }}
        ref={ref}
      >
        <Typography
          textAlign="center"
          maxWidth="68ch"
          fontWeight="normal"
          fontSize="38px"
          variant="h3"
          fontFamily="'Grand Hotel', cursive"
        >
          {t("homepage.quote")}
        </Typography>

        <Typography fontStyle="italic" marginTop={1.5} textAlign="right">
          {t("homepage.quote_source")}
        </Typography>
      </motion.div>
    </Banner>
  );
};

export default HomeInterSections;
