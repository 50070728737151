import React, { useState } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import ConceptsPage from "../pages/ConceptsPage";
import ContactPage from "../pages/ContactPage";
import FormationsPage from "../pages/FormationsPage";
import Home from "../pages/Home";
import Legal from "../pages/Legal";
import NotFound from "../pages/NotFound";
import Policy from "../pages/Policy";
import PresentationPage from "../pages/PresentationPage";
import guestPathes from "./guestPathes";

const GuestRoutes = () => {
  const [locale] = useState<string>("fr");

  return useRoutes([
    {
      path: guestPathes[locale].home,
      element: <Home />,
    },
    {
      path: guestPathes[locale].concepts,
      element: <ConceptsPage />,
    },
    {
      path: guestPathes[locale].presentation,
      element: <PresentationPage />,
    },
    {
      path: guestPathes[locale].not_found,
      element: <NotFound />,
    },
    {
      path: guestPathes[locale].formations,
      element: <FormationsPage />,
    },
    {
      path: guestPathes[locale].contact,
      element: <ContactPage />,
    },
    {
      path: guestPathes[locale].policy,
      element: <Policy />,
    },
    {
      path: guestPathes[locale].legal,
      element: <Legal />,
    },
    {
      path: "/",
      element: <Navigate to={guestPathes[locale].home} replace />,
    },
    {
      path: "*",
      element: <Navigate to={guestPathes[locale].not_found} replace />,
    },
  ]);
};

export default GuestRoutes;
