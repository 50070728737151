import React from "react";
import { motion } from "framer-motion";
import { Typography } from "@mui/material";
import Banner from "./common/Banner";

const PolicyHeader = () => {
  return (
    <Banner cover image="/static/header.jpg">
      <motion.div
        animate={{ scale: [0, 1], opacity: [0, 1] }}
        transition={{ duration: 0.5 }}
      >
        <Typography fontFamily="Grand Hotel" variant="h1" fontWeight="400">
          Politique de confidentialité
        </Typography>
      </motion.div>
    </Banner>
  );
};

export default PolicyHeader;
