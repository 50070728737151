import { Box, Container, styled, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const Root = styled("div")`
  background: #f8f8f8;
  display: flex;
  flex-direction: column;
  padding: 50px;
`;

const BioCtn = styled("div")`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    :nth-of-type(even) {
      flex-direction: column-reverse;
    }
    .name {
      text-align: center;
    }
  }
`;

const ProfilePicture = styled("div")`
  min-width: 300px;
  min-height: 300px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

const textMargin = `30px`;
const BioTextCtn = styled("div")`
  max-width: 65ch;
  :nth-of-type(odd) {
    margin-right: ${textMargin};
  }

  :nth-of-type(even) {
    margin-left: ${textMargin};
  }
`;

const Bios = () => {
  const { t } = useTranslation();

  return (
    <Root>
      <Container>
        {/* Sidonie */}
        <BioCtn>
          <ProfilePicture
            style={{ backgroundImage: 'url("/static/sido.png")' }}
          />
          <BioTextCtn>
            <Box sx={{ display: "flex" }}>
              <Typography sx={{ mb: 1 }} variant="h5" className="name">
                {t("presentation_page.sidonie.fullname")}
              </Typography>
              <Typography
                sx={{ ml: 1 }}
                component="a"
                rel="noreferrer"
                href="https://www.linkedin.com/in/sidonie-fillion-bb7278115/"
                target="_blank"
              >
                <LinkedInIcon sx={{ color: "#0a66c2", fontSize: "30px" }} />
              </Typography>
            </Box>
            <Typography>{t("presentation_page.sidonie.bio")}</Typography>
          </BioTextCtn>
        </BioCtn>

        {/* Aurélia */}
        <BioCtn sx={{ marginLeft: "auto" }}>
          <BioTextCtn>
            <Box sx={{ display: "flex" }}>
              <Typography
                className="name"
                variant="h5"
                sx={{ textAlign: "right", mb: 1, ml: "auto" }}
              >
                {t("presentation_page.aurelia.fullname")}
              </Typography>
              <Typography
                sx={{ ml: 1 }}
                component="a"
                rel="noreferrer"
                href="https://www.linkedin.com/in/aur%C3%A9lia-verrier-978826144/"
                target="_blank"
              >
                <LinkedInIcon sx={{ color: "#0a66c2", fontSize: "30px" }} />
              </Typography>
            </Box>
            <Typography sx={{ textAlign: "right" }}>
              {t("presentation_page.aurelia.bio")}
            </Typography>
          </BioTextCtn>
          <ProfilePicture
            style={{ backgroundImage: 'url("/static/aurelia.png")' }}
          />
        </BioCtn>
      </Container>
    </Root>
  );
};

export default Bios;
