export default function GlobalStyle(theme: any) {
  return {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          // background: "#282c34",
        },
      },
    },
  };
}
