import React from "react";
import Page from "../components/common/Page";
import HomeFormations from "../components/home/formations/HomeFormations";
import HomeHeader from "../components/home/HomeHeader";
import HomeInterSections from "../components/home/presentation/HomeInterSections";
import HomePres from "../components/home/presentation/HomePres";
import HomePresButton from "../components/home/presentation/HomePresButton";
import HomePresSecondPart from "../components/home/presentation/HomePresSecondPart";
import { Box, styled } from "@mui/material";

const VideoResponsive = styled("div")`
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    border: none;
  }
`;

const Home: React.FC = () => {
  return (
    <Page title="page_titles.home">
      <HomeHeader />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "50px",
          textAlign: "center",
          background: "#f8f8f8",
        }}
      >
        <Box
          sx={{
            width: "60%",
            overflow: "hidden",
            borderRadius: "10px",
            "@media screen and (max-width: 500px)": {
              width: "320px",
            },
          }}
        >
          <VideoResponsive>
            <iframe
              width="auto"
              height="auto"
              src="https://www.youtube.com/embed/qh6I8wRnTB0"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </VideoResponsive>
        </Box>
      </Box>

      <div
        style={{
          paddingTop: "50px",
          paddingBottom: "50px",
          background: "#f8f8f8",
        }}
      >
        <HomePres />
      </div>

      <HomePresButton />

      <HomeInterSections />

      <HomePresSecondPart />

      <HomeFormations />
    </Page>
  );
};

export default Home;
