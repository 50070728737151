import { Container, Typography, styled } from "@mui/material";
import React from "react";
import Page from "../components/common/Page";
import ContactHeader from "../components/ContactHeader";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";

const SFlex = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const Item = styled("div")`
  width: 250px;
  text-align: center;
  margin: 15px 50px;
`;

const SContainer = styled(Container)`
  margin-top: 50px;
  margin-bottom: 50px;
`;

const TitleCtn = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
`;

const ContactPage = () => {
  return (
    <Page title="page_titles.contact">
      <ContactHeader />
      <SContainer>
        <Typography textAlign="center" variant="h3" sx={{ mb: 2 }}>
          Pour plus d'informations, vous pouvez nous joindre :
        </Typography>

        <SFlex>
          <Item>
            <TitleCtn>
              <LocalPhoneRoundedIcon />
              <Typography fontWeight="bold" sx={{ ml: 1 }}>
                Contact
              </Typography>
            </TitleCtn>

            <Typography>Tel: 06 16 74 05 80</Typography>
            <Typography>
              Mail:{" "}
              <a href="mailto:contact@rainbowafc.com">contact@rainbowafc.com</a>
            </Typography>
          </Item>

          <Item>
            <TitleCtn>
              <AccessTimeRoundedIcon />
              <Typography fontWeight="bold" sx={{ ml: 1 }}>
                Horaire
              </Typography>
            </TitleCtn>

            <Typography>Tous les jours</Typography>
            <Typography>09:00 - 12:00</Typography>
          </Item>
        </SFlex>
      </SContainer>
    </Page>
  );
};

export default ContactPage;
